import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
	sidebarMenuSection: any = [];
	currentAdmin:any = '';
	role: string | undefined;
	constructor(private router: Router) { }

	ngOnInit() {
		this.currentAdmin = JSON.parse(localStorage.getItem('currentAdmin')||'{}');
		//console.log(this.currentAdmin);
		this.role = this.currentAdmin['role'];
		if (this.role == "Administrator" || this.role == "Superadmin") {
			this.sidebarMenuSection = [
				{
					name: 'Dashboard',
					imageClass: 'fas fa-tachometer-alt',
					showOtherOption: false,
					otherSection: [
						{
							name: 'Dashboard',
							imageClass: '',
							route: '/main/dashboard'
						},
						{
							name: 'Keeper',
							imageClass: '',
							route: '/main/dashboard/keeper'
						},
						{
							name: 'Leever',
							imageClass: '',
							route: '/main/dashboard/leever'
						}
					]
				},
				{
					name: 'Accounts',
					imageClass: 'fas fa-user-circle',
					showOtherOption: false,
					otherSection: [
						{
							name: 'Users',
							imageClass: '',
							showSubSectionOption: false,
							subSection: [
								{
									name: 'Users',
									imageClass: '',
									route: '/main/verified-users'
								},
								/*{
									name: 'Email Non-Verified Users',
									imageClass: '',
									route: '/main/unverified-users'
								},
								{
									name: 'Snooze Account',s
									imageClass: '',
									route: '/main/users/snooze-account'
								},
								{
									name: 'Deleted/ Deactivated',
									imageClass: '',
									route: '/main/users/deleted'
								}*/
							]
						},
						{
							name: 'Verification',
							imageClass: '',
							showSubSectionOption: false,

							subSection: [
								{
									name: 'ID Verification',
									imageClass: '',
									route: '/main/validation/for-validation'
								},
								{
									name: 'Keeper',
									imageClass: '',
									route: '/main/validation/for-keeper'
								},
								{
									name: 'Leever',
									imageClass: '',
									route: '/main/validation/for-leever'
								},
								{
									name: 'Agent',
									imageClass: '',
									route: '/main/validation/for-agent'
								},
								{
									name: 'Refer & Earn',
									imageClass: '',
									route: ''
								},
								{
									name: 'Verified Accounts',
									imageClass: '',
									route: '/main/validation/validated-accounts'
								}
							]
						}

					]
				},
				{
					name: 'Spaces',
					imageClass: 'fas fa-warehouse',
					otherSection: [
						{
							name: 'Overview',
							imageClass: '',
							route: '/main/space-overview'
						},
						{
							name: 'Space-Details',
							imageClass: '',
							route: '/main/spaces'
						},
						{
							name: 'Follow Up',
							imageClass: '',
							route: '/main/follow-up'
						},
						{
							name: 'All spaces',
							imageClass: '',
							route: '/main/space-quality'
						},
						{
							name: 'Discrepancy Report',
							imageClass: '',
							route: '/main/space-discrepancy'
						},
						{
							name: 'Profiles',
							imageClass: '',
							showSubSectionOption: false,
							subSection: [
								{
									name: 'Active',
									imageClass: '',
									route: '/main/space-profile/space-active'
								},
								{
									name: 'Price Adjustments',
									imageClass: '',
									route: '/main/space-profile/space-price'
								},
								{
									name: 'Deactivated',
									imageClass: '',
									route: '/main/space-profile/space-deactive'
								}
							]
						}
					]
				},
				/*{
					name: 'Peeves',
					imageClass: '',
					showOtherOption: false,
					otherSection: [
						{
							name: 'Request',
							imageClass: '',
							route: '/main/request'
						}
					]
				},*/
				{
					name: 'Transaction',
					imageClass: 'fas fa-exchange-alt',
					showOtherOption: false,
					otherSection: [
						// {
						// 	name: 'Reservation Request',
						// 	imageClass: '',
						// 	route: '/main/transaction-reservation'
						// },
						{
							name: 'Reservations',
							imageClass: '',
							showSubSectionOption: false,
							subSection: [
								{
									name: 'Received Request List',
									imageClass: '',
									route: '/main/reservation-request'
								},
								{
									name: 'Approved Requests',
									imageClass: '',
									route: '/main/reservation-request-approved'
								},
								{
									name: 'Rejected Requests',
									imageClass: '',
									route: '/main/reservation-request-rejected'
								},
								{
									name: 'Deleted Requests',
									imageClass: '',
									route: '/main/reservation-request-delete'
								},
								{
									name: 'Expired Requests',
									imageClass: '',
									route: '/main/expired-request'
								},
								{
									name: 'Completed Bookings',
									imageClass: '',
									route: '/main/completed-booking'
								},
								{
									name: 'Overview',
									imageClass: '',
									route: '/main/overview'
								},
								{
									name: 'Cancellation',
									imageClass: '',
									route: '/main/reservations/cancellation'
								},
							]
						},
						{
							name: 'Booking',
							imageClass: '',
							showSubSectionOption: false,
							subSection: [
								{
									name: 'Current',
									imageClass: '',
									route: '/main/bookings'
								},
								// {
								// 	name: 'Termination',
								// 	imageClass: '',
								// 	route: '/main/booking/termination'
								// },
							]
						},
						{
							name: 'Financial',
							imageClass: '',
							showSubSectionOption: false,
							subSection: [
								//{
									//name: 'Revenue',
									//imageClass: '',
									//route: '/main/revenue'
								//},
								//{
									//name: 'Insurance',
									//imageClass: '',
									//route: '/main/financial/insurance'
								//},
								{
									name: 'Due Billings',
									imageClass: '',
									route: '/main/financial/invoicing'
								},
								{
									name: 'Paid Billings',
									imageClass: '',
									route: '/main/financial/paid'
								},
								{
									name: 'Payout',
									imageClass: '',
									route: '/main/compensation'
								},
								{
									name: 'Payments',
									imageClass: '',
									route: '/main/financial/payments'
								},
								{
									name: 'Deposits',
									imageClass: '',
									route: '/main/financial/deposits'
								}
							]
						}
					]
				},
				{
					name: 'Termination',
					imageClass: 'fa fa-stop-circle',
					showOtherOption: false,
					otherSection: [
						{
							name: 'On Going',
							imageClass: '',
							route: '/main/termination/on-going-termination'
						},
						{
							name: 'Complete',
							imageClass: '',
							route: '/main/termination/completd-termination'
						}
					]
				},
				{
					name: 'Community',
					imageClass: 'fas fa-object-group',
					showOtherOption: false,
					otherSection: [
						{
							name: 'Reviews',
							imageClass: '',
							route: '/main/reviews'
						},
						{
							name: 'Help Tickets',
							imageClass: 'fas fa-ticket-alt',
							route: '/main/help-tickets'
						},
						{
							name: 'Feedback',
							imageClass: '',
							route: '/main/feedback'
						},
						{
							name: 'Ratings',
							imageClass: '',
							route: '/main/ratings'
						},
						{
							name: 'Escalations',
							imageClass: '',
							route: '/main/escalations'
						},
						{
							name: 'Transactions',
							imageClass: '',
							showSubSectionOption: false,
							subSection: [
								{
									name: 'Space Viewing',
									imageClass: '',
									route: '/main/transactions/space-viewing'
								},
								{
									name: 'Contract Start/End Date',
									imageClass: '',
									route: '/main/transactions/contract-date'
								},
								{
									name: 'Contracts',
									imageClass: '',
									route: '/main/transactions/contracts'
								},
								{
									name: 'Pick Up Drop Off Point',
									imageClass: '',
									route: '/main/transactions/pickup'
								}
							]
						}
					]
				},
				{
					name: 'Map Surfing',
					imageClass: '',
					showOtherOption: false,
					otherSection: [
						{
							name: 'Searched Locations and Searched From',
							imageClass: '',
							route: '/main/search-location'
						}
					]
				},
				{
					name: 'Front End',
					imageClass: '',
					showOtherOption: false,
					otherSection: [
						{
							name: 'Password Management',
							imageClass: '',
							route: '/main/password-management'
						},
						{
							name: 'Promos',
							imageClass: '',
							route: '/main/promos'
						},
						{
							name: 'Settings',
							imageClass: '',
							route: '/main/settings'
						},
						{
							name: 'Content Management',
							imageClass: '',
							route: '/main/content-management'
						},
						{
							name: 'Platform Activity',
							imageClass: '',
							route: '/main/platform-activity'
						}
					]
				},
				{
					name: 'Administrators',
					imageClass: 'fas fa-user-tie',
					showOtherOption: false,
					otherSection: [
						{
							name: 'Role/Access Management',
							imageClass: '',
							route: '/main/administrators'
						},
						{
							name: 'Settings',
							imageClass: '',
							route: '/main/admin-settings'
						},
						{
							name: 'Team Members',
							imageClass: '',
							route: '/main/team-members'
						}
					]
				},
				{
					name: 'Corporate',
					imageClass: '',
					showOtherOption: false,
					otherSection: [
						{
							name: 'Registration',
							imageClass: '',
							route: '/main/registration'
						},
						{
							name: 'Spaces',
							imageClass: '',
							route: '/main/corporate-spaces'
						},
						{
							name: 'Financials',
							imageClass: '',
							route: '/main/financials'
						}
					]
				},
				{
					name: 'Business Partners',
					imageClass: '',
					showOtherOption: false,
					otherSection: [
						{
							name: 'Registration',
							imageClass: '',
							route: '/main/buisness-registration'
						},
						{
							name: 'Performance',
							imageClass: '',
							route: '/main/performance'
						}
					]
				},
				/*{
				  name: 'Help',
				  imageClass: 'fas fa-ticket-alt',
				  showOtherOption: false,
				  otherSection: [
					{
					  name: 'Ticket',
					  imageClass: '',
					  route: '/main/ticket'
					}
				  ]
				} */
			];
		} else if (this.role == "Manager") {
			this.sidebarMenuSection = [
				{
					name: 'Dashboard',
					imageClass: 'fas fa-tachometer-alt',
					showOtherOption: false,
					otherSection: [
						{
							name: 'Dashboard',
							imageClass: '',
							route: '/main/dashboard'
						},
						{
							name: 'Keeper',
							imageClass: '',
							route: '/main/dashboard/keeper'
						},
						{
							name: 'Leever',
							imageClass: '',
							route: '/main/dashboard/leever'
						}
					]
				},
				{
					name: 'Accounts',
					imageClass: 'fas fa-user-circle',
					showOtherOption: false,
					otherSection: [
						{
							name: 'Users',
							imageClass: '',
							showSubSectionOption: false,
							subSection: [
								{
									name: 'Email Verified Users',
									imageClass: '',
									route: '/main/verified-users'
								},
								{
									name: 'Email Non-Verified Users',
									imageClass: '',
									route: '/main/unverified-users'
								},
								{
									name: 'Snooze Account',
									imageClass: '',
									route: '/main/users/snooze-account'
								},
								{
									name: 'Deleted/ Deactivated',
									imageClass: '',
									route: '/main/users/deleted'
								}
							]
						},
						{
							name: 'Verification',
							imageClass: '',
							showSubSectionOption: false,
							subSection: [
								{
									name: 'ID Verification',
									imageClass: '',
									route: '/main/validation/for-validation'
								}, {
									name: 'Keeper',
									imageClass: '',
									route: '/main/validation/for-keeper'
								},
								{
									name: 'Leever',
									imageClass: '',
									route: '/main/validation/for-leever'
								},
								{
									name: 'Agent',
									imageClass: '',
									route: '/main/validation/for-agent'
								},
								{
									name: 'Verified Accounts',
									imageClass: '',
									route: '/main/validation/validated-accounts'
								}
							]
						}
					]
				},
				{
					name: 'Spaces',
					imageClass: 'fas fa-warehouse',
					otherSection: [
						{
							name: 'Overview',
							imageClass: '',
							route: '/main/space-overview'
						},
						{
							name: 'Space-Details',
							imageClass: '',
							route: '/main/spaces'
						},
						{
							name: 'Follow Up',
							imageClass: '',
							route: '/main/follow-up'
						},
						{
							name: 'Quality Check',
							imageClass: '',
							route: '/main/space-quality'
						},
						{
							name: 'Discrepancy Report',
							imageClass: '',
							route: '/main/space-discrepancy'
						},
						{
							name: 'Profiles',
							imageClass: '',
							showSubSectionOption: false,
							subSection: [
								{
									name: 'Active',
									imageClass: '',
									route: '/main/space-profile/space-active'
								},
								{
									name: 'Price Adjustments',
									imageClass: '',
									route: '/main/space-profile/space-price'
								},
								{
									name: 'Deactivated',
									imageClass: '',
									route: '/main/space-profile/space-deactive'
								}
							]
						}
					]
				},
				{
					name: 'Peeves',
					imageClass: '',
					showOtherOption: false,
					otherSection: [
						{
							name: 'Request',
							imageClass: '',
							route: '/main/request'
						}
					]
				},
				{
					name: 'Transaction',
					imageClass: 'fas fa-exchange-alt',
					showOtherOption: false,
					otherSection: [
						// {
						// 	name: 'Reservation Request',
						// 	imageClass: '',
						// 	route: '/main/transaction-reservation'
						// },
						{
							name: 'Reservations',
							imageClass: '',
							showSubSectionOption: false,
							subSection: [
								{
									name: 'Received Request List',
									imageClass: '',
									route: '/main/reservation-request'
								},
								{
									name: 'Approved Requests',
									imageClass: '',
									route: '/main/reservation-request-approved'
								},
								{
									name: 'Rejected Requests',
									imageClass: '',
									route: '/main/reservation-request-rejected'
								},
								{
									name: 'Deleted Requests',
									imageClass: '',
									route: '/main/reservation-request-delete'
								},
								{
									name: 'Expired Requests',
									imageClass: '',
									route: '/main/expired-request'
								},
								{
									name: 'Completed Bookings',
									imageClass: '',
									route: '/main/completed-booking'
								},
								{
									name: 'Overview',
									imageClass: '',
									route: '/main/reservations/overview'
								},
								{
									name: 'Cancellation',
									imageClass: '',
									route: '/main/reservations/cancellation'
								},
							]
						},
						{
							name: 'Booking',
							imageClass: '',
							showSubSectionOption: false,
							subSection: [
								{
									name: 'Current',
									imageClass: '',
									route: '/main/bookings'
								},
								// {
								// 	name: 'Termination',
								// 	imageClass: '',
								// 	route: '/main/booking/termination'
								// },
							]
						},
						{
							name: 'Financial',
							imageClass: '',
							showSubSectionOption: false,
							subSection: [
								//{
								//name: 'Revenue',
								//imageClass: '',
								//route: '/main/revenue'
								//},
								//{
								//name: 'Insurance',
								//imageClass: '',
								//route: '/main/financial/insurance'
								//},
								{
									name: 'Due Billings',
									imageClass: '',
									route: '/main/financial/invoicing'
								},
								{
									name: 'Paid Billings',
									imageClass: '',
									route: '/main/financial/paid'
								},
								{
									name: 'Payout',
									imageClass: '',
									route: '/main/compensation'
								},
								{
									name: 'Payments',
									imageClass: '',
									route: '/main/financial/payments'
								},
								{
									name: 'Deposits',
									imageClass: '',
									route: '/main/financial/deposits'
								}
							]
						}
					]
				},
				{
					name: 'Termination',
					imageClass: 'fa fa-stop-circle',
					showOtherOption: false,
					otherSection: [
						{
							name: 'On Going',
							imageClass: '',
							route: '/main/termination/on-going-termination'
						},
						{
							name: 'Complete',
							imageClass: '',
							route: '/main/termination/completd-termination'
						}
					]
				},
				{
					name: 'Community',
					imageClass: 'fas fa-object-group',
					showOtherOption: false,
					otherSection: [
						{
							name: 'Reviews',
							imageClass: '',
							route: '/main/reviews'
						},
						{
							name: 'Help Tickets',
							imageClass: 'fas fa-ticket-alt',
							route: '/main/help-tickets'
						},
						{
							name: 'Feedback',
							imageClass: '',
							route: '/main/feedback'
						},
						{
							name: 'Ratings',
							imageClass: '',
							route: '/main/ratings'
						},
						{
							name: 'Escalations',
							imageClass: '',
							route: '/main/escalations'
						},
						{
							name: 'Transactions',
							imageClass: '',
							showSubSectionOption: false,
							subSection: [
								{
									name: 'Space Viewing',
									imageClass: '',
									route: '/main/transactions/space-viewing'
								},
								{
									name: 'Contract Start/End Date',
									imageClass: '',
									route: '/main/transactions/contract-date'
								},
								{
									name: 'Contracts',
									imageClass: '',
									route: '/main/transactions/contracts'
								},
								{
									name: 'Pick Up Drop Off Point',
									imageClass: '',
									route: '/main/transactions/pickup'
								}
							]
						}
					]
				}
			]
		}
	}

	/**
	 *
	 * @param selectedOption
	 * @param index
	 */

	menuSectionClick(selectedOption: any, index: any, subIndex: any) {

		// tslint:disable-next-line: variable-name
		this.sidebarMenuSection.forEach((element: { sectionSelected: boolean; otherSection: any[]; showOtherOption: boolean; route: any; }, _index: any) => {
			if (index === _index) {
				element.sectionSelected = !element.sectionSelected;
				if (element.otherSection.length) {
					element.showOtherOption = !element.showOtherOption;
					element.otherSection.forEach((subElement: { subSectionSelected: boolean; showSubSectionOption: boolean; }, ind: any) => {
						if (subIndex === ind) {
							subElement.subSectionSelected = !subElement.subSectionSelected;
							subElement.showSubSectionOption = !subElement.showSubSectionOption;
							element.sectionSelected = true;
							element.showOtherOption = true;
						}
						else {
							subElement.subSectionSelected = false;
							subElement.showSubSectionOption = false;
						}
					});
				} else {
					this.router.navigate([`${element.route}`]);
				}
			} else {
				element.sectionSelected = false;
				element.showOtherOption = false;
			}
		});
	}

}
