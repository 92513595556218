import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { PaginationComponent } from './pagination/pagination.component';
import { DirectivesModule } from '../directives/directives.module';
import { FormsModule } from '@angular/forms';
import { UploaderComponent } from './uploader/uploader.component';
import { FileUploadModule } from 'ng2-file-upload';
import { DialogComponent } from './dialog/dialog.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    PaginationComponent,
    UploaderComponent,
    DialogComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    DirectivesModule,
    FileUploadModule,
    MatDialogModule,
    OverlayModule,
    MatButtonModule
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    PaginationComponent,
    UploaderComponent,
    DialogComponent,
    MatDialogModule
  ],
  entryComponents:[DialogComponent]
})
export class SharedModule { }
